
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
@Component
export default class Home extends Vue {
  customMetaData: any = {
    properties: [
      {
        name: "id",
        type: "string",
        description: "Уникальный идентификатор компонента.",
        caption: "ID",
        editor: "readonly",
        priority: 1,
        group: "general",
        visibleInTable: true,
        validations: [],
        config: {},
      },
      {
        name: "name",
        type: "string",
        description:
          'Строковое уникальное имя компонента, которое используется как ЧПУ при адресации. Уникальность обеспечивается в рамках одного класса компонентов. Например компоненты класса "Статья" и "Страница" могут иметь одинаковый ЧПУ, но двух статей с одинаковыми ЧПУ быть не должно. ',
        caption: "Системное имя",
        editor: "systemname",
        priority: 100,
        group: "general",
        visibleInTable: true,
        validations: [
          {
            validator: "max",
            value: 150,
            errorMessage: "Поле не может содержать больше чем 150 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может ,быть пустым",
          },
        ],
        config: {
          bindedFieldName: "normalizedName",
        },
      },
      {
        name: "normalizedName",
        type: "string",
        description:
          "Заголовок компонента. Обычно используется как основной заголовок при визуализации как страницы, так и виджетов.",
        caption: "Подпись",
        editor: "string",
        priority: 100,
        group: "general",
        visibleInTable: false,
        validations: [
          {
            validator: "max",
            value: 250,
            errorMessage: "Поле не может содержать больше чем 250 символов",
          },
          {
            validator: "required",
            value: true,
            errorMessage: "Поле не может быть пустым",
          },
        ],
        config: {
          bindedFieldName: "name",
        },
      },
    ],
  };

  createItemFields: any = [
    {
      editor: "string",
      name: "name",
      config: {},
      attrs: {
        type: "text",
        label: "Назание роли",
      },
      validations: [
        {
          validator: "max",
          value: 150,
          errorMessage: "Поле не может содержать больше чем 150 символов",
        },
        {
          validator: "required",
          value: true,
          errorMessage: "Поле не может быть пустым",
        },
      ],
      watchers: [
        {
          changeModel: "generateNormalizedName",
        },
      ],
    },
  ];

  createItemActions: any = {
    generateNormalizedName: (model) => {
      model.normalizedName = model.name?.toUpperCase?.();
    },
  };

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Роли",
        disabled: true,
      },
    ];
  }

  createItemModel: any = {
    name: "",
    normalizedName: "",
  };

  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "roles",
  });
  $confirm: any;
  $message: any;

  async remove(item: any) {
    const confirm = await this.$confirm(
      "Вы действительно хотите удалить данную роль",
    );
    if (!confirm) return;
    try {
      await this.dataSource.remove(item.id);
      this.$message("Успешно удалён");
    } catch (error: any) {
      this.$message(error?.response.data, "error");
    }
  }
}
